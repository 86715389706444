import React from "react"
import Block from "../../core/components/block"
import Banner from "../../client/compositions/banner"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import ContactUs from "../../client/compositions/contact-us"
import Row from "../../core/components/row"
import Sticky from "../../core/components/sticky"
import Tagline from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Terms Page
 * @see https://zpl.io/2GKzrLE
 */
function TermsPage() {
  return (
    <article id="page-terms" className="page-content">
      {quickMenu}

      <Banner
        title={
          <>
            Terms &<br /> Conditions
          </>
        }
      />

      <Row id="terms" className="long-text">
        <Block className="block-content">
          <Sticky margin={40}>
            Updated: 15.05.2020
            <br />© Invinity
          </Sticky>
        </Block>
        <Block className="block-content">
          <p>
            InVinity is not liable or responsible for any damage to computer
            hardware or software arising from the distribution of a computer
            virus.
          </p>
          <p>
            InVinity is not responsible for service delays resulting from
            unforeseen circumstances, such as technical problems that are beyond
            our control.
          </p>
          <p>
            InVinity is not liable for incidental, indirect, consequential,
            special, or punitive, damages of any kind, including lost revenues
            or profits, loss of business, or loss of data, in any way related to
            this service or for any claim, loss, or injury based on errors,
            omissions, or any other inaccuracies appearing on
            InVinityWineRack.com.
          </p>
          <p>
            It is prohibited to post or transmit to this site any unlawful,
            threatening, libelous, defamatory, obscene, pornographic, or other
            material that would violate any law.
          </p>
          <p>
            Fees are refunded in accordance with the InVinity Return Policy.
          </p>
          <p>
            The client must provide true and accurate information regarding
            name, address, e-mail address, phone number and payment details.
            Failure to provide accurate information will result in refusal of
            service. The client assumes all responsibility for any problems,
            errors, as well as legal liability, resulting from false
            information. All information submitted by the client is
            confidential.
          </p>
          <p>
            InVinity is not liable for the fraudulent use of any credit card or
            other payment method that may be submitted to this website.
          </p>
          <p>
            InVinity reserves the right to discontinue or terminate operation of
            its services without notice at any time and for any reason.
          </p>
          <p>
            The terms and conditions of use of InVinity may be modified,
            amended, reorganized, rewritten, or revoked at any time, for any
            reason and without notice.
          </p>
          <p>
            In the event of a client violating any or all of the terms and
            conditions mentioned above, InVinity reserves the right to terminate
            all service and contractual obligations with the client.
          </p>
          <p>
            InVinity reserves the right to refuse service for any reason and
            without explanation.
          </p>
        </Block>
      </Row>

      <ContactUs>
        <DecorationFluid image="/terms/contact.small.jpg" />
      </ContactUs>
      <Tagline />
    </article>
  )
}

export default TermsPage
